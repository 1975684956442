let burger = document.querySelector('.js-btn')
let menu = document.querySelector('.js-menu')
let body = document.querySelector('body')

if(burger){
  burger.addEventListener('click', () => {
    burger.querySelector('.js-btn__inner').classList.toggle('is-active')
    menu.classList.toggle('is-active');
    body.classList.toggle('is-overflow')
  });
}



